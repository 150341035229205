import React, { ReactElement } from 'react'
import { PageProps } from 'gatsby'

import Layout from '@/components/layout'

function Page(_props: PageProps) {
  return (
    <Layout>
      <h1>Hva er nakkesmerter?</h1>
      <p>
        Hvordan behandles nakkesmerter? Nakkesmerter er en av de vanligste årsakene til at folk oppsøker kiropraktor.
        Det vi si at dette er noe vi behandler daglig og har veldig god erfaring med. Hver behandling varer fra 20-30
        minutt.
      </p>

      <p>
        Nakkesmerter er når man har smerter som sitter i nakken. Det kan være en stikkende, brennende, ilende eller
        verkende smerte. Smerten kan være lokal på en side, kjennes på begge sider eller over et stort område i nakken.
        Noen ganger kan smerten stråle ut i armer eller frem i hodet. Med ledd og skiveproblem i nakken kan pasienten
        også oppleve nummenhetsfølelse eller prikking i armer. Smerter i nakke grunnet ledd- eller muskelproblem kan
        også manifestere seg som hodeverk/migrene.
      </p>
    </Layout>
  )
}

export default Page
